@import '../../../assets/constants/variables.scss';

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;

  form {
    display: flex;
    flex-direction: row;
    gap: 42px;

    .left {
      display: flex;
      flex-direction: column;
      gap: 24px;
    }
  }
  .checkbox {
    padding-top: 10px;
    justify-content: flex-start;

    @include bottom_text(#000000);
  }

  .button_container {
    padding-top: 20px;
    display: flex;
    flex-direction: row;
    gap: 18.5px;

    .negative {
      background-color: transparent;
      color: $BUTTON_POSITIVE_BACKGROUND;
    }
  }

  a {
    text-decoration-line: none;
  }

  .link {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.02em;
    color: $BUTTON_POSITIVE_BACKGROUND;
  }
}
