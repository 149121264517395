.react-datepicker {
  display: flex;
  border-radius: 0;
  border-top-right-radius: 8px !important;
  border-top-left-radius: 8px !important;
}

.react-datepicker__header {
  display: flex;
  flex-direction: column;
  background-color: transparent;
  border: 0;
  gap: 14px;
  padding-top: 14px;
  border-top-right-radius: 0px !important;
  border-top-left-radius: 0px !important;
}
.react-datepicker__month {
  margin: 0 16px 0 16px;
}
.react-datepicker__day {
  font-family: 'Open Sans';
  font-size: 16px;
  font-weight: 600;
  width: 2.45rem;
  line-height: 2.45rem;

  &-name {
    font-family: 'Open Sans';
    font-size: 16px;
    font-weight: 600;
    width: 2.45rem;
    line-height: 2.45rem;
  }
  &--in-selecting-range {
    background-color: #e63335 !important;
    opacity: 0.8;
  }
  &--in-range {
    background-color: #e63335 !important;
  }
  &--range-end {
    color: #ffffff !important;
  }
  &--selected {
    background-color: #e63335 !important;
  }
  &--keyboard-selected {
    background-color: transparent;
    color: black;
    &:hover {
      background-color: inherit;
    }
  }
  &--today {
    background-color: #e63335;
    border-radius: 0.3rem;
    opacity: 1;
    color: #ffffff;
    &:hover {
      background-color: #e63335;
    }
  }
  &--outside-month {
    opacity: 0.4;
    pointer-events: none;
  }
}

.react-datepicker__day .react-datepicker__day--today {
  opacity: 0.65;
}
