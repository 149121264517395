@import '../../../assets/constants/variables.scss';

.wrapper {
  display: flex;
  align-items: center;
  gap: 24px;
  flex-direction: row;

  .calendar_button {
    height: 42px;
  }
  .selected_dates {
    font-family: Poppins;
    font-size: 20px;
    font-weight: 300;
    letter-spacing: 0.02em;
    text-align: center;
    color: #e63335;
  }
}
.react-datepicker__header {
  border: 0;
}
.container {
  position: absolute;
  top: 40px;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #ffffff;
  margin-top: 40px;
  border-radius: 18px;
  padding: 19px 37.5px;
  border: 0;
  font-family: 'Poppins', sans-serif;
  z-index: 1000;

  @include shadow();

  &_navigation {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 8px;
    margin-bottom: 14px;
  }

  .navigation_button {
    font-size: 14px;
    font-weight: 300;
    background-color: #f5f6f9;
    color: $BLACK;
    padding: 8px 16px;

    &:hover {
      background-color: rgba(230, 51, 53, 0.08);
    }
  }

  &_footer {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    padding: 12px 0 14px 0;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px !important;
    background-color: #f5f6f9;
  }
}

.calendar {
  border: 0;
  background-color: #f5f6f9;
}

.calendar_header {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;

  &_month {
    font-family: 'Open Sans';
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0.01em;
    text-align: left;
    color: #292d34;
  }
  &_icon {
    cursor: pointer;
  }
}
