@import '../../../assets/constants/variables.scss';

.double_title {
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: flex-end;
  align-items: center;

  &_primary {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    // font-size: calc(64 * 100vw / 1440);

    @include font-size(64, 46);
    font-weight: 600;
    color: #fff;
    z-index: 1;

    @media screen and (max-width: 768px) {
      font-size: 44px;
      line-height: 65px;
    }
    @media screen and (min-width: 1440px) {
      font-size: 72px;
      line-height: 108px;
    }
  }
  &_secondary {
    display: flex;
    font-weight: 800;
    @include font-size(140, 100);
    // font-size: calc(140 * 100vw / 1440);
    color: $TITLE_SECONDARY;
    z-index: 10;

    @media screen and (max-width: 768px) {
      font-size: 75px;
      line-height: 112px;
    }

    @media screen and (min-width: 1440px) {
      vertical-align: bottom;
      font-size: 124px;
      line-height: 185px;
    }
  }
}
