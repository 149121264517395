.icon_button {
  all: unset;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;

  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
}
