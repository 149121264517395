@import '../../../assets/constants/variables.scss';

.buttonComponent {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  padding: $BUTTON_VERTICAL_PADDING $BUTTON_HORIZONTAL_PADDING;
  border-radius: $BUTTON_BORDER_RADIUS;
  font-size: 16px;
  line-height: 24px;
  font-family: 'Poppins', sans-serif;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #ffffff;
  max-height: 42px;

  cursor: pointer;

  &_positive {
    border: 1px solid $BUTTON_POSITIVE_BACKGROUND;
    background-color: $BUTTON_POSITIVE_BACKGROUND;
    &:hover {
      opacity: 0.8;
    }
  }

  &_negative {
    border: 1px solid #ee3032;

    background-color: $BUTTON_NEGATIVE_BACKGROUND;
    &:hover {
      background-color: $BUTTON_POSITIVE_BACKGROUND;
    }
  }
}
