@import '../../../assets/constants/variables.scss';

.loading {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(28, 28, 28, 0.28);
  z-index: 999999;
  display: flex;
  justify-content: center;
  align-items: center;
}
