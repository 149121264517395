.rate_overView {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  gap: 54px;

  &_count {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;

    .avgRate {
      font-size: 52px;
      font-weight: 500;
      line-height: 78px;
      letter-spacing: 0.02em;
      color: rgba(28, 28, 28, 0.8);
    }

    .all {
      padding-top: 10.33px;
      font-size: 12px;
      font-weight: 500;
      line-height: 18px;
      letter-spacing: 0.02em;
      color: rgba(28, 28, 28, 0.64);
    }
  }
  &_overview {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
    gap: 7px;

    &_item {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 12px;
      transition: width 0.2s ease-in;

      .text {
        font-size: 14px;
        font-weight: 500;
        line-height: 21px;
        letter-spacing: 0.02em;
        text-align: center;
        color: rgba(28, 28, 28, 0.72);
      }

      .row {
        width: 100%;
        height: 10px;
        background-color: #e8eaed;
        border-radius: 10px;
        .filled {
          display: flex;
          background-color: #e63335;
          height: 100%;
          border-radius: 10px;
        }
      }
    }
  }
}
