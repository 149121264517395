@import '../../../assets/constants/variables.scss';

.event {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: $EVENT_HEIGHT;
  max-width: $EVENT_WIDTH;
  width: 100%;
  background-color: $WHITE;
  border-radius: $EVENT_BORDER_RADIUS;
  overflow: hidden;
  position: relative;

  @include shadow();

  &:hover {
    box-shadow: 0px 0px 14px 0px #ff4b4b75;
  }

  .liked,
  .like {
    position: absolute;
    right: 16px;
    top: 16px;
    visibility: visible;
    cursor: pointer;
  }

  .like {
    visibility: hidden;
  }

  .photo {
    height: 320px;
    width: 100%;
    object-fit: cover;
    img {
      height: 100%;

      &:hover {
        transition: all 3s;
      }
    }
  }

  .photo:hover img {
    transform: scale(1.1);
  }

  &:hover .like {
    visibility: visible;
  }

  &_info {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 18px 16px;
    overflow: hidden;

    &_name {
      font-weight: 500;
      font-size: 17px;
      color: $BLACK;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    &_date_and_type {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
      padding-top: 4px;
      gap: 40px;
      .text {
        font-weight: 400;
        font-size: 14.58px;
        text-align: center;
      }
      .date {
        color: rgba(28, 28, 28, 0.54);
        white-space: nowrap;
      }
      .address {
        text-align: left;
        color: rgba(28, 28, 28, 0.7);

        @media screen and (max-width: 1150px) {
          display: -webkit-box;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
      }
    }

    &_djs {
      padding-top: 8px;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 14px;
      .container {
        padding: 6px 12px;
        border-radius: 40px;
        .text {
          color: $BLACK;
          font-size: 14px;
          font-weight: 400;
        }
      }
    }
  }

  &_actions {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 48px;
    border-top: 0.85px solid rgba(28, 28, 28, 0.09);

    .price {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .text {
        font-weight: 400;
        font-size: 12px;
        text-align: center;
      }

      &_from {
        color: rgba(28, 28, 28, 0.44);
      }
      &_value {
        color: rgba(28, 28, 28, 0.88);
      }
    }

    .book {
      all: unset;
      display: flex;
      flex: 3;
      background-color: $BUTTON_POSITIVE_BACKGROUND;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      .title {
        font-weight: 500;
        font-size: 27px;
        color: $WHITE;
        text-align: center;
      }

      &:hover {
        opacity: 0.8;
      }
    }
  }
}
