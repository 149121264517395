@import '../../../assets/constants/variables.scss';

.select {
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: $TEXT_INPUT_MAX_WIDTH;
  height: 70px;
  width: 100%;
  overflow: 'hidden';

  .helper_text {
    padding-left: 16px;
    font-size: 16px;
    line-height: 16px;
    color: $WHITE;
  }

  .container {
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
    background-color: $WHITE;
    padding: 8px 16px;
    border-radius: 29px;
    position: absolute;
    left: 0;
    right: 0;
    top: 26px;
    min-height: 24px;
    z-index: 2;
    overflow: hidden;
    box-sizing: unset !important;

    &_row {
      display: flex;
      flex: 1;
      height: 100%;
      // padding-bottom: 12px;
    }

    .input {
      font-family: Poppins, sans-serif;
      display: flex;
      flex: 1;
      // width: 100%;
      border: 0;
      outline: none;
      font-style: normal;
      font-weight: 300;
      font-size: 16px;
      line-height: 24px;
      color: #4e4b5c;
      background-color: transparent;
    }

    input[type='button'] {
      cursor: pointer;
    }

    &_children {
      display: flex;
      flex-direction: column;
      overflow-y: scroll;
      overflow-x: hidden;
      gap: 4px;
    }
  }

  .label {
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    color: #4e4b5c;
  }
}

.option {
  max-width: $TEXT_INPUT_MAX_WIDTH;
  height: 24px;
  // width: 100%;
  padding: 8px 0;
  padding-left: 8px;
  margin-right: 12px;

  &:hover {
    cursor: pointer;
    background-color: rgba(255, 101, 101, 1);
    border-radius: 29px;

    .text {
      color: $WHITE;
    }
  }

  .text {
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    color: #4e4b5c;
  }

  .text_selected {
    color: $WHITE;
  }
}

.option_selected {
  // width: 100%;
  background-color: #ee3032;
  border-radius: 29px;
}
