.link {
  font-family: 'Poppins', sans-serif;
  color: #ffffff;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-decoration: none;
  text-align: center;
  align-self: center;
  white-space: nowrap;

  &:hover {
    opacity: 0.8;
  }

  svg {
    display: block;
  }

  &_active {
    color: #ff3b3d;
  }
}
