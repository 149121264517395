@import '../../../assets/constants/variables.scss';

.base {
  max-width: $TEXT_INPUT_MAX_WIDTH;
  min-width: $TEXT_INPUT_MIN_WIDTH;
  width: 100%;
}

.textAreaContainer {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  gap: 10px;
  border-radius: 29px;

  &_disabled {
    border: 0;
    border-radius: 0px;
  }

  .helperText {
    padding-left: 16px;
    font-size: 16px;
    line-height: 16px;
    color: $WHITE;
  }

  &_textAreaContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $WHITE;
    padding: 8px 16px;
    border-radius: 29px;

    .leftSide {
      margin-right: 10px;
    }
  }

  &_textArea {
    display: flex;
    flex: 1;
    width: 100%;
    min-height: 137px;
    border: 0;
    outline: none;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    color: #4e4b5c;
    background-color: transparent;
    resize: none;
    &_disabled {
      background-color: #fafafa;
    }
  }

  // &_boldInput {
  //   font-weight: bold;
  // }

  &_iconContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      cursor: pointer;
    }
  }
}

.error {
  color: #ff3739;
  font-size: 16px;
  font-weight: 500;
  padding-left: 16px;
}
