@import '../../../assets/constants/variables.scss';

.tab_navigation {
  display: flex;
  flex-direction: column;

  gap: 40px;

  .tabs {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 32px;
    background-color: $BACKGROUND_LIGHT_1;
    border-radius: 50px;
    overflow: hidden;

    &_item {
      display: flex;
      width: 100%;
      height: 100%;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      &_selected {
        background-color: #e63335;
        cursor: unset;
      }

      .text {
        font-size: 14px;
        font-weight: 500;
        line-height: 21px;
        letter-spacing: 0.02em;
        text-align: center;
        color: #e63335;
        //   text-align: center;

        &_selected {
          color: #ffffff;
        }
      }
    }
  }
}
