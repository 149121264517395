@import '../../../assets/constants/variables.scss';

.homeBanner {
  display: flex;
  justify-content: center;
  align-items: center;
  .phone {
    width: 291px;
    height: 600px;
    transition: transform linear;
  }

  &_title_wrapper {
    position: absolute;

    &_title {
      max-width: 1016px;
      font-size: 64px;
      line-height: 96px;
      font-family: 'Poppins', sans-serif;
      color: #fff;
      text-align: center;
      font-weight: 600;
      letter-spacing: 0.32px;
      white-space: pre-line;

      @media screen and (max-width: 768px) {
        font-size: 32px;
        line-height: 48px;
        max-width: 312px;
      }
    }
  }

  &_video {
    width: 100%;
    min-height: 800px;
  }
}
