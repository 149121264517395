.comments {
  display: flex;
  flex-direction: row;
  background-color: #ffffff;
  border-radius: 26px;
  padding: 50px 0;
  gap: 26px;

  .content {
    width: 100%;
    height: 100%;
  }
}

.comment {
  display: flex;
  width: 100%;
  flex-direction: row;
  gap: 28px;

  .left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4px;
    width: 99px;

    img {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      object-fit: cover;
      object-position: center;
      background-color: #e8eaed;
    }

    .name {
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0.02em;
      color: rgba(28, 28, 28, 0.8);
      text-align: center;
    }
  }

  .right {
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 24px;

    .info {
      display: flex;
      flex-direction: row;
      gap: 28.5px;

      .date {
        font-size: 14px;
        font-weight: 500;
        line-height: 21px;
        letter-spacing: 0.02em;
        text-align: center;
        color: rgba(28, 28, 28, 0.88);
      }
    }
    .text {
      font-size: 16px;
      font-style: italic;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.02em;
      white-space: pre-line;
      color: rgba(28, 28, 28, 0.8);
    }
  }
}
