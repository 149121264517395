.error {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  padding-top: 98px;

  .info {
    font-size: 24px;
    font-weight: 500;
    color: #ffffff;
    text-align: center;
  }
}
