@import '../../../assets/constants/variables.scss';

.newsTicker {
  display: flex;
  height: 64px;
  align-items: center;
  overflow: hidden;
  cursor: pointer;
  white-space: nowrap;
  position: relative;

  &_background {
    position: absolute;
    inset: 0;
    background-color: red;
  }

  &_text {
    font-weight: 600;
    color: $WHITE;
    font-size: 32px;
    -moz-transform: translateX(100%);
    -webkit-transform: translateX(100%);
    transform: translateX(-100%);

    -moz-animation: news-ticker 15s 0s linear infinite;
    -webkit-animation: news-ticker 15s 0s linear infinite;
    animation: news-ticker 15s 0s linear infinite;

    &:hover {
      animation-play-state: paused;
    }
  }

  /* for Firefox */
  @-moz-keyframes news-ticker {
    from {
      -moz-transform: translateX(300%);
    }
    to {
      -moz-transform: translateX(-100%);
    }
  }

  /* for Chrome */
  @-webkit-keyframes news-ticker {
    from {
      -webkit-transform: translateX(300%);
    }
    to {
      -webkit-transform: translateX(-100%);
    }
  }

  @keyframes news-ticker {
    from {
      -moz-transform: translateX(300%);
      -webkit-transform: translateX(300%);
      transform: translateX(300%);
    }
    to {
      -moz-transform: translateX(-100%);
      -webkit-transform: translateX(-100%);
      transform: translateX(-100%);
    }
    // from {
    //   -moz-transform: translateX(100%);
    //   -webkit-transform: translateX(100%);
    //   transform: translateX(100%);
    // }
    // to {
    //   -moz-transform: translateX(-100%);
    //   -webkit-transform: translateX(-100%);
    //   transform: translateX(-100%);
    // }
  }
}
