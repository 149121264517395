@import '../../../assets/constants/variables.scss';

.modal {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.48);
  inset: 0;
  width: 100%;
  height: 100%;
  z-index: 11;
  animation-name: modal;
  animation-duration: 0.5s;
  @keyframes modal {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  &_container {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    margin-top: 148px;
    z-index: 11;
    background-color: $BACKGROUND_LIGHT_3;
    border-radius: 18px;
    min-width: 200px;
    min-height: 200px;
    width: max-content;
    height: max-content;
    padding: 68px 60px 80px 60px;
  }
}
