.events {
  display: grid;
  grid-template-columns: repeat(3, minmax(280px, 400px));
  column-gap: 24px;
  row-gap: 48px;

  @media screen and (max-width: 1024px) {
    grid-template-columns: repeat(2, minmax(280px, 400px));
    justify-content: center;
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(1, minmax(280px, 400px));
  }
}

.venues {
  display: grid;
  grid-template-columns: repeat(3, minmax(280px, 350px));
  column-gap: 24px;
  row-gap: 48px;
  width: 100%;
  min-height: 200px;

  @media screen and (max-width: 1024px) {
    grid-template-columns: repeat(2, minmax(280px, 350px));
    justify-content: center;
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(1, minmax(280px, 350px));
  }
}

.empty {
  padding-top: 56px;
  max-width: 406px;
  width: 100%;
  align-self: center;
}
