.counter {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 16px;

  .inputContainer {
    width: 100px;
    min-width: unset;
  }
  .inputWrapper {
    border: 0.5px solid #e63335 !important;
  }
  .input {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 23px;
    color: #ee3032;
  }

  .button {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #ee3032;
  }
}
