.checkbox {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;

  svg {
    flex-shrink: 0;
  }
  cursor: pointer;
}
