@import '../../../assets/constants/variables.scss';

.switch {
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 20px;
  width: 100%;

  .toggle {
    all: unset;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    background-color: #ffffff;
    border-radius: 75px;
    overflow: hidden;
    position: relative;
    align-self: center;

    .item {
      display: flex;
      align-items: center;

      cursor: pointer;
      list-style-type: none;
      padding: 6.5px 22px;

      .text,
      .text_selected {
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 0.02em;
        color: rgba(28, 28, 28, 0.44);
        text-align: center;
        z-index: 2;
      }

      .text_selected {
        color: $WHITE;
      }
    }

    &_selected {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      border-radius: 75px;
      background-color: #e63335;
      transition: all 0.2s ease-in-out;
    }
  }

  .content {
    display: flex;
    flex: 1;
    justify-content: center;

    // &_container {
    //   all: unset;

    //   display: grid;
    //   grid-template-columns: 100% 100%;
    //   grid-template-rows: auto auto;

    //   transition: all 0.2s ease-in-out;

    //   li {
    //     all: unset;
    //     display: flex;
    //     flex: 1;
    //     justify-content: center;
    //     align-items: flex-start;

    //     // height: fit-content;
    //   }
    // }
  }
}
