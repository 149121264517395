$MOBILE_MAX_WIDTH: 768px;

$WHITE: #ffffff;
$BLACK: #1c1c1c;
$BACKGROUND_BLACK: #0f0f0f;
$BACKGROUND_LIGHT_1: #f1f1f1;
$BACKGROUND_LIGHT_2: #d9d9d9;
$BACKGROUND_LIGHT_3: #f7f7f7;

$BACKGROUND_DARK_1: #2d2d2d;

$SHADOW: rgba(122, 119, 119, 0.33);

$HEADER_VERTICAL_PADDING: 13px;
$HEADER_HORIZONTAL_PADDING: 120px;
$MOBILE_HEADER_HORIZONTAL_PADDING: 16px;
$MOBILE_HEADER_VERTICAL_PADDING: 13px;
$HEADER_BACKGROUND: rgba(0, 0, 0, 0.8);
$FOOTER_BACKGROUND: #1c1c1c;
$FOOTER_HORIZONTAL_PADDING: 120px;
$FOOTER_TOP_PADDING: 88px;
$FOOTER_BOTTOM_PADDING: 48px;

$BUTTON_HORIZONTAL_PADDING: 16px;
$BUTTON_VERTICAL_PADDING: 4px;
$BUTTON_BORDER_RADIUS: 50px;
$BUTTON_POSITIVE_BACKGROUND: #e63335;
$BUTTON_NEGATIVE_BACKGROUND: transparent;
$BUTTON_TEXT_COLOR: #ffffff;

$TEXT_INPUT_MAX_WIDTH: 380px;
$TEXT_INPUT_MIN_WIDTH: 200px;
$TEXT_INPUT_MAX_HEIGHT: 80px;

$TITLE_SECONDARY: #ffffff12;

$EVENT_WIDTH: 380px;
$EVENT_HEIGHT: 548px;
$EVENT_IMAGE_HEIGHT: 320px;
$EVENT_BORDER_RADIUS: 22px;

@mixin shadow() {
  box-shadow: 0px 0px 14px 0px $SHADOW;
}

@mixin imageBackground($image, $minHeight, $size: cover) {
  min-height: $minHeight;
  background-image: url($image);
  background-size: $size;
  background-repeat: no-repeat;
  background-position: center;
}

@mixin auth {
  @include imageBackground('../../assets/images/Background.png', 93vh);
  display: flex;
  flex-direction: row;

  .error {
    min-width: calc(50% + 150px) !important;
    padding-right: 40px !important;
  }
  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    min-width: 50%;
    gap: 24px;
    background-color: rgba(0, 0, 0, 0.35);
    backdrop-filter: blur(6.5px);
    padding-bottom: 44px;
    padding-top: 98px;
    padding-right: 121px;
    padding-left: 121px;

    @media screen and (min-width: 1440px) {
      justify-content: flex-end;
      padding-left: 0;
      padding-right: 220px;
    }

    @media screen and (max-width: 768px) {
      flex-wrap: wrap-reverse;
      padding-right: 48px;
      padding-left: 48px;
    }
    @media screen and (max-width: 500px) {
      justify-content: center;
      width: 100%;
      padding-right: 12px;
      padding-left: 12px;
    }
  }

  .inputContainer {
    display: flex;
    flex-direction: column;
    width: 380px;

    @media screen and (max-width: 500px) {
      width: 100%;
    }

    .title {
      font-weight: 600;
      font-size: 30px;
      color: $WHITE;
      text-align: center;
    }

    form {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 24px;
      padding-top: 13px;
      padding-bottom: 16px;
    }

    .submitButton {
      margin-top: 24px;
      align-self: center;
      &:disabled {
        background-color: light-dark(rgba(239, 239, 239, 0.3), rgba(19, 1, 1, 0.3));
        color: light-dark(rgba(16, 16, 16, 0.3), rgba(255, 255, 255, 0.3));
        border-color: light-dark(rgba(118, 118, 118, 0.3), rgba(195, 195, 195, 0.3));
        cursor: default;
        opacity: 1;
      }
    }
  }

  .oAuth {
    display: flex;
    flex-direction: column;
    gap: 24px;
    justify-content: space-between;
    align-items: center;
    padding-top: 24px;

    .divider {
      display: flex;
      width: 100%;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      gap: 13px;
      &_line {
        width: 100%;
        height: 1px;
        background-color: rgba(255, 255, 255, 0.53);
        border-radius: 1px;
      }
    }

    .icons {
      display: flex;
      flex-direction: row;
      justify-content: center;
      gap: 39px;
    }
  }

  .bottom_text {
    font-weight: 400;
    font-size: 16px;
    color: $WHITE;

    .link {
      color: #ff3739;
    }
  }

  .padding_top56 {
    padding-top: 56px;
  }
}

@mixin bottom_text($color: $WHITE, $font-weight: 400) {
  .bottom_text {
    font-weight: $font-weight;
    font-size: 16px;
    color: $color;

    .link {
      color: #ff3739;
    }
  }
}

@mixin font-size($size, $lineHeight) {
  font-size: calc($size * 100vw / 1440);
  line-height: calc($lineHeight * 100vw / 1440);
}

@mixin line-clamp($number: 1) {
  display: -webkit-box;
  -webkit-line-clamp: $number;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
