@import '../../../assets/constants/variables.scss';

.file_picker {
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow: hidden;

  .upload {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 85px;
    position: absolute;
    visibility: hidden;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.63);
    border-radius: 20px;
    pointer-events: none;

    .text {
      font-size: 20px;
      font-weight: 700;
      letter-spacing: 0.02em;
      color: $WHITE;
      text-align: center;
    }

    .trash {
      position: absolute;
      top: 16px;
      right: 16px;
      z-index: 2000;
      pointer-events: auto;
    }
  }

  .input {
    position: relative;

    &:hover .upload {
      visibility: visible;
    }
  }

  .helperText {
    padding-left: 16px;
    font-size: 16px;
    line-height: 16px;
    color: $WHITE;
  }

  input {
    opacity: 0;
    position: absolute;
    z-index: -1;
  }

  // .image {
  //   width: 100%;
  //   height: 100%;
  //   background-color: #f7f7f7;
  //   border-radius: 20px;
  //   img {
  //     width: 100%;
  //     height: 100%;
  //     border-radius: 20px;
  //     object-fit: contain;
  //   }
  // }

  img {
    width: 100%;
    height: 100%;
    border-radius: 20px;
    background-color: #f7f7f7;
    object-fit: fill;
  }

  label {
    display: block;
    cursor: pointer;
    width: 248px;
    height: 214px;
  }

  // &_boldInput {
  //   font-weight: bold;
  // }
}
