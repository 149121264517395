@import '../../../assets/constants/variables.scss';

.venue {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
  max-width: 384px;
  min-width: 280px;
  width: 100%;
  position: relative;
  cursor: pointer;

  &:hover {
    img {
      box-shadow: 0px 0px 24px 0px #ff4b4b75;
    }
  }

  img {
    background-color: $BACKGROUND_LIGHT_2;
    height: 224px;
    width: 100%;
    border-radius: 26px;
    object-fit: cover;
    @include shadow();
  }
  &_info {
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 12px;

    &_left {
      display: flex;
      flex: 1;
      align-items: center;
      overflow: hidden;
      gap: 22px;

      .name {
        font-weight: 400;
        color: $WHITE;
        font-size: 18px;
        line-height: 27px;
        overflow: hidden !important;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .type {
        pointer-events: none;
        padding: 4.5px 8px;
      }
    }
  }
  &_address {
    display: flex;
    align-items: center;
    gap: 5.5px;
    svg {
      flex-shrink: 0;
    }
    .text {
      font-weight: 600;
      font-style: italic;
      font-size: 14px;
      color: rgba(28, 28, 28, 0.6);
    }
  }

  &:hover .like {
    visibility: visible;
  }

  .liked,
  .like {
    position: absolute;
    right: 16px;
    top: 16px;
    visibility: visible;
    cursor: pointer;
  }

  .like {
    visibility: hidden;
  }
}
