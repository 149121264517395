@import '../../../assets/constants/variables.scss';

.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: linear-gradient(180deg, #0F0F0F -15.44%, rgba(15, 15, 15, 0) 100%);

  z-index: 99999;
  padding: $HEADER_VERTICAL_PADDING 0;

  &_content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  &_left {
    display: flex;
    gap: 32px;
  }
  &_logo {
    margin: 0 auto;
    padding: 0 20px;
  }
  &_right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 32px;

    .profile {
      position: relative;
      .menu {
        all: unset;
        position: absolute;
        display: flex;
        flex-direction: column;
        background-color: $BACKGROUND_DARK_1;
        border-radius: 4px;
        top: 36px;
        right: 8px;

        &_item {
          cursor: pointer;
          list-style-type: none;

          padding: 4.5px 16px;

          &:hover {
            opacity: 0.8;
          }
          &_top {
            padding-top: 16px;
          }

          &_bottom {
            padding-bottom: 16px;
          }
        }
      }
    }
  }

  &_get_started {
    background-color: #fff;
    border: unset;
    color: #000;
  }
}

.mobile_header {
  display: flex;
  flex-direction: column;
  gap: 40px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: $MOBILE_HEADER_VERTICAL_PADDING $MOBILE_HEADER_HORIZONTAL_PADDING;
  background-color: $HEADER_BACKGROUND;
  z-index: 99999;
  max-height: 68px;

  transition: all 0.5s ease-in-out;
  overflow: hidden;

  &_opened {
    max-height: 1000px;
  }

  .navigation {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    .hamburger {
      width: 34px;
      height: 22px;
      position: relative;
      // margin: 50px auto;
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transition: 0.5s ease-in-out;
      -moz-transition: 0.5s ease-in-out;
      -o-transition: 0.5s ease-in-out;
      transition: 0.5s ease-in-out;
      cursor: pointer;

      span {
        display: block;
        position: absolute;
        height: 2px;
        width: 100%;
        background: #ffffff;
        border-radius: 4px;
        opacity: 1;
        left: 0;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: 0.25s ease-in-out;
        -moz-transition: 0.25s ease-in-out;
        -o-transition: 0.25s ease-in-out;
        transition: 0.25s ease-in-out;
      }

      span:nth-child(1) {
        top: 0px;
        -webkit-transform-origin: left center;
        -moz-transform-origin: left center;
        -o-transform-origin: left center;
        transform-origin: left center;
      }

      span:nth-child(2) {
        top: 8px;
        width: 19px;
        transform-origin: left center;
      }

      span:nth-child(3) {
        top: 16px;
        width: 3px;
        -webkit-transform-origin: left center;
        -moz-transform-origin: left center;
        -o-transform-origin: left center;
        transform-origin: left center;
      }

      &_opened {
        span:nth-child(1) {
          -webkit-transform: rotate(45deg);
          -moz-transform: rotate(45deg);
          -o-transform: rotate(45deg);
          transform: rotate(45deg);
          top: -4.5px;
        }
        span:nth-child(2) {
          width: 0%;
          opacity: 0;
        }
        span:nth-child(3) {
          -webkit-transform: rotate(-45deg);
          -moz-transform: rotate(-45deg);
          -o-transform: rotate(-45deg);
          transform: rotate(-45deg);
          top: 19px;
          width: 100%;
        }
      }
    }

    .logo {
      margin: 0 auto;
    }
  }

  .menus {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 62px;
    a {
      align-self: unset;
    }

    .left {
      display: flex;
      flex: 1;
      flex-direction: column;
      align-items: flex-start;
      gap: 32px;

      a {
        align-self: unset;
      }
    }
    .right {
      display: flex;
      flex-direction: column;
      gap: 32px;
      align-items: flex-end;
    }
  }
}
