@import '../../../assets/constants/variables.scss';

.layout {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  min-height: 100vh;

  &_children {
    display: flex;
    flex-direction: column;
    width: 100%;
    flex: 1;
  }

  .ticker_text {
    background-image: unset;
    -webkit-text-fill-color: unset;
  }

  &_footer {
    display: flex;
    flex-direction: column;

    &_base {
      background-color: $FOOTER_BACKGROUND;

      &_content {
        display: flex;
        flex: 1;
        flex-direction: column;
        gap: 80px;
        padding: $FOOTER_TOP_PADDING $FOOTER_HORIZONTAL_PADDING $FOOTER_BOTTOM_PADDING
          $HEADER_HORIZONTAL_PADDING;

        @media screen and (max-width: $MOBILE_MAX_WIDTH) {
          padding: 56px 44px 48px 44px;
          gap: 45px;
        }
      }

      .links {
        display: grid;
        grid-template-columns: repeat(4, 1fr);

        gap: 28px;

        @media screen and (max-width: $MOBILE_MAX_WIDTH) {
          grid-template-columns: repeat(2, 1fr);
          gap: 24px;
        }

        .link_item {
          text-align: left;
          font-weight: 400;
          &:last-child {
            grid-column: 4;
            @media screen and (max-width: $MOBILE_MAX_WIDTH) {
              grid-column: 2;
            }
          }
        }
      }

      .subscribe {
        display: flex;
        flex-wrap: wrap;
        gap: 16px;
        &_button {
          height: 42px;
        }
      }

      .social {
        display: flex;
        justify-content: space-between;
        gap: 59px;
        align-self: center;
        @media screen and (max-width: 375px) {
          gap: unset;
          width: 100%;
        }
        &_item {
          width: 48px;
          height: 48px;
        }
      }

      .copyright {
        align-self: center;
        text-align: center;
        font-weight: 400;
        font-size: 14px;
        color: rgba(255, 255, 255, 0.48);
      }
    }
  }
}
