.rating {
  display: flex;
  flex-direction: row;
  // justify-content: flex-start;
  align-items: center;
}

.not_selectable {
  cursor: unset;
  &:hover {
    opacity: 1;
  }
}
